import {useSelector as reactUseSelector} from 'react-redux'
import {RootState} from '#state/types'
import {useSelector as originalUseSelector} from 'react-redux'
import reduxStore from '#state/store'

// Cache for promises that are thrown when a property is not loaded
const promiseCache: Record<string, Promise<string>> = {}

// Log of slices that have been accessed
// Mostly for debugging purposes
const accessLog = []

function isLoggedIn(target: RootState) {
    return target.session?.authenticated && target.session?.association
}

/**
 * useSelector that throws a promise if a slice is not loaded
 * This is designed to work with react suspense
 */
export const useSuspenseSelector = <TSelected>(selector: (state: RootState) => TSelected, equalityFn?: (left: TSelected, right: TSelected) => boolean): TSelected => {

    // Creates a modified selector that
    const modifiedSelector = (state: RootState) => {
        const proxyState = new Proxy(state, {
            get(target, prop: string) {
                if (!accessLog.includes(prop)) {
                    accessLog.push(prop)
                    console.log('*** First access of ' + prop)
                }
                if (isLoggedIn(target) && target.loaded[prop] === false) {
                    console.log('*** Waiting for slice ' + prop, target.loaded)
                    printStackTrace()
                    if (!promiseCache[prop]) {
                        promiseCache[prop] = new Promise((resolve) => {
                            const unsubscribe = reduxStore.subscribe(() => {
                                console.log('*** Checking if slice ' + prop + ' is loaded')
                                if (reduxStore.getState().loaded[prop]) {
                                    console.log('*** Resolves promise for slice ' + prop)
                                    resolve(prop);
                                    unsubscribe();
                                }
                            });
                        });
                    }
                    console.log('*** Throws promise for slice ' + prop)
                    throw promiseCache[prop];
                }
                return target[prop]
            },
        })

        return selector(proxyState)
    }

    return originalUseSelector(modifiedSelector, equalityFn)
}

// Prints a stack trace without throwing an error
// Mostly for debugging purposes
function printStackTrace() {
    try {
        throw new Error("StackTrace");
    } catch (e) {
        console.log(e.stack);
    }
}




type RootStateSelector = <Selected>(selector: (state: RootState) => Selected, equalityFn?: (left: Selected, right: Selected) => boolean) => Selected

/**
 * Re-exporting the useSelector function with a type definition
 */
export const useSelector = reactUseSelector as RootStateSelector
