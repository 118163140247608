import useOrganizationWarnings from "#app/services/useOrganizationWarnings"
import useUiSetting from "#app/services/useUiSetting"
import { isLoggedIn } from "#app/state/selectors"
import { useSelector } from "#app/state/useSelector"
import { DepositorDto, PartnerCustomerDto } from "@fixrate/fixrate-query"
import { userIsAccountCreator } from "./accountCreatorUtils"

export type OnboardingWizardStatus = {
    status: {
        terms: boolean,
        board: boolean,
        orderProcess: boolean,
        identificationDocument?: boolean,
        owners: boolean,
        signingRights: boolean,
        powerOfAttorney: boolean,
        accounts: boolean,
        customerDeclarationInfo: boolean,
        customerDeclaration: boolean,
        invitations: boolean,
        status: boolean,
    },
    progress: number,
    currentSlug: string,
    wizardActive: boolean,
    loaded: boolean
}

export const useOnboardingWizardStatus = (depositor: DepositorDto | PartnerCustomerDto) : OnboardingWizardStatus => {
    const loggedIn = useSelector(isLoggedIn)
    const sessionId = useSelector(state => state.session.id)
    const depositorId = (depositor as DepositorDto)?.id || (depositor as PartnerCustomerDto)?.depositorId
    const isPartnerAdminView = useSelector(state => state.session.organisationType === 'PARTNER')
    const orders = useSelector(state => state.orders)
    const loaded = useSelector(state => state.loaded)
    const documents = useSelector(state => state.documents)
    const authDocs = Object.keys(documents).map(key => documents[key])
        .filter(d => d.documentType === 'AUTHORIZATION_DOCUMENT')
        .filter(d => d.owner === depositorId)
    
    // TODO: This is a temporary hack because uiSettings is loaded after funds. Remove when suspense loaders is implemented
    const isLoaded = loaded.funds

    const hasSignedAuthDocs = authDocs.length > 0
        ? authDocs.filter(d => d.userCanSign).every(d => d.signedByUser)
        : true

    const hasPartnerProposals = depositor?.partnerRelations?.some(pr => pr.partnerProposals.length > 0)

    const isFullyRegistered = orders?.length > 0 || (!!depositor?.fullyRegisteredAt && hasPartnerProposals)

    const isPartnerAccountant = depositor?.users?.find(u => u.id === sessionId)?.roles?.find(r => r === 'DEPOSITOR_PARTNER_ACCOUNTANT')
    const organizationWarnings = useOrganizationWarnings(depositor)
    const isSelfServiced = (depositor as DepositorDto)?.depositorSupportCategory === 'SELF_SERVICED' || false
    const isAccountHolder = loggedIn && userIsAccountCreator(depositor?.users.find(u => u.id === sessionId))
    //Remove the condition of isPartnerAccountant to show the wizard for all users later
    const showWizard = (loggedIn && !isFullyRegistered) && !!isPartnerAccountant
    const [finishedSteps, _] = useUiSetting('onboardingWizardStep', depositor ? {[depositorId]: 0} : {})
    const status = {
        'terms': organizationWarnings?.terms.length === 0,
        'board': !organizationWarnings?.organisationalRoles.some(w => w.type === 'MISSING_ORGANISATIONAL_ROLES'),
        'orderProcess': !organizationWarnings.organisationalRoles.some(w => w.type === 'MISSING_SECURITY_ROLES'),
        'identificationDocument': !organizationWarnings.users.some(w => w.type === 'MISSING_IDENTIFICATION_DOCUMENT'),
        'owners': isSelfServiced ? true : !organizationWarnings.users.some(w => w.type === 'MISSING_BENEFICIAL_OWNERS'),
        'signingRights': isSelfServiced ? true : !organizationWarnings.users.some(w => w.type === 'MISSING_AUTHORIZATION_SIGNERS'),
        'powerOfAttorney': isSelfServiced
            ? true
            : !organizationWarnings.users.some(w => w.type === 'MISSING_AUTHORIZATION_DOCUMENT') && hasSignedAuthDocs,
        'accounts': organizationWarnings?.accounts.length === 0,
        'customerDeclarationInfo': !organizationWarnings?.customerDeclaration.some(w => w.type === 'NEED_INFO_FOR_CUSTOMER_DECLARATION'),
        'customerDeclaration': organizationWarnings?.customerDeclaration.length === 0,
        'invitations': !organizationWarnings.users.some(w =>
            w.type === 'PERSON_NEEDS_INFO_FOR_INVITE' ||
            w.type === 'PERSON_NEEDS_INVITE' ||
            w.type === 'AUTHORIZATION_SIGNER_NEEDS_INFO_FOR_INVITE' ||
            w.type === 'AUTHORIZATION_SIGNER_NEEDS_INVITE'
        ),
        'status': organizationWarnings?.users.length === 0
    }

    // const remove identificationDocument if not account holder
    if (!isAccountHolder) {
        delete status.identificationDocument
    }

    if (isPartnerAccountant) {
        delete status.invitations
        delete status.status
    }

    const firstIncompleteStep = Object.values(status).indexOf(false)
    const stepsDone = firstIncompleteStep === -1 ? Object.keys(status).length : firstIncompleteStep
    const progress = isPartnerAdminView
        ? stepsDone  / Object.keys(status).length * 100
        : Math.min(finishedSteps[depositorId] || 0, stepsDone) / Object.keys(status).length * 100
    const lastDoneItemSlug = Object.keys(status).find(key => status[key] === false)

    return {
        loaded: isLoaded,
        status,
        progress,
        currentSlug: lastDoneItemSlug ? `/organizations/onboarding/${depositorId}/${lastDoneItemSlug}` : '/organizations/onboarding/terms',
        wizardActive: showWizard,
    }
}
