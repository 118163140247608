import {DialogTitle, DialogTitleProps, IconButton, Stack} from '@mui/material'
import { useTracking } from '#app/services/useTracking'
import useSupportPane from '#app/services/useSupportPane'
import { SupportCategoryNameType } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'

type Props = DialogTitleProps & {
    onClose: () => void
    supportCommonName?: SupportCategoryNameType
    helpContextPage?: string
}

export default function FxDialogTitle(props: Props) {
    const {trackEvent} = useTracking({ page: props.helpContextPage ? props.helpContextPage : undefined })
    const supportPane = useSupportPane()

    const showSupportArticle = () => {
        trackEvent({event: 'open-context-help'})
        supportPane.show(props.supportCommonName)
    }

    return (
        <DialogTitle {...props} sx={{...props.sx, borderBottom: '1px solid rgba(0, 0, 0, 0.12)', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'}>
                { props.children }
                { props.supportCommonName && (
                    <IconButton sx={{ml: 0.5}} color='primary' data-cy="showSupportArticleButton" aria-label="show support article" onClick={showSupportArticle}>
                        <i className='ri-question-line'/>
                    </IconButton>
                )}
            </Stack>
            <IconButton data-cy="closeDialogButton" aria-label="close" onClick={props.onClose}>
                <i className="ri-close-line"/>
            </IconButton>
        </DialogTitle>
    )
}
