import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'loaded',

    // Initial state is pre-filled with "false" for all slices that may throw a promise
    // if accessed before they are loaded
    initialState: {
        ads: false,
        depositor: false,
        documents: false,
        orders: false,
        deposits: false,
        banks: false,
        depositorNames: false,
        depositors: false,
        identificationDocument: false,
        bankDocuments: false,
        profile: false,
        notificationSettings: false,
        interestRateChange: false,
        history: false,
        newCustomerDeclarations: false,
        dashboard: false,
        bank: false,
        messages: false,
        depositorOffers: false,
        depositMissingAccountStatements: false,
        partner: false,
        partnerNames: false,
        invoiceReport: false,
        depositDeviations: false,
        companyPublicInformation: false,
        funds: false,
        fundBuyOrders: false,
        fundSellOrders: false,
        fundPlacements: false,
        fundCustomers: false,
        unresolvedTasks: false,
        task: false,
    },

    reducers: {
        setIsLoaded(state, action) {
            state[action.payload] = true
        },
        setIsNotLoaded(state, action) {
            state[action.payload] = false
        },
    },
})

const {actions, reducer} = slice
export const {setIsLoaded, setIsNotLoaded} = actions
export default reducer
