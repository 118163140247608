/*
    Functions that updates the adStatus redux slice

    The status flags are only used to update the UI

    All constraints are also checked on the server
 */
import {setAdStatus} from '#state/reducers/adStatus'
import {correctDate} from './dateandtime'
import equal from 'fast-deep-equal'
import {AdDto} from '@fixrate/fixrate-query'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

// Number of milliseconds an ad is active after user has requested deactivation
const VALIDITY_DELAY_MS = 1000 * 60 * 60 * 6   //ms * minutes * hours * 6

// Number of milliseconds an ad can be deactivated instantly after it is published
const REGRET_ACTIVATION_PERIOD_MS = 1000 * 60 * 10   //ms * minutes * 10

function updateAdStatus(dispatch, getState) {
    try {
        const ads = getState().ads
        const newStatus = calculateAdStatus(ads)
        const currentStatus = getState().adStatus
        if (!equal(currentStatus, newStatus)) {
            dispatch(setAdStatus(newStatus))
        }
    } catch (e) {
        console.error('Failed to update ad status', e)
    }
}

export function useUpdateAdStatusRunner() {
    const dispatch = useDispatch()
    useEffect(() => {
        const job = setInterval(() => {
            dispatch(updateAdStatus)
        }, 1000) // runs every second
        return () => {
            clearInterval(job)
        }
    }, [dispatch])
}

export function calculateAdStatus(ads: AdDto[]) {
    const now = correctDate()
    return ads.reduce((acc, ad) => {
        const validity = ad.validity ? new Date(ad.validity) : null
        const published = ad.published ? new Date(ad.published) : null

        const active = !ad?.validity || now.getTime() < validity?.getTime()

        // Can deactivate if one of
        // - ad has no validity set
        // - ad has validity, but the new validity would be before the current validity
        const canDeactivate = active && (!validity || (validity.getTime() - now.getTime()) > VALIDITY_DELAY_MS)

        // Can regret if the ad is published less than 10 minutes ago
        const canRegretActivation = canDeactivate && (((now.getTime() - published?.getTime?.()) < REGRET_ACTIVATION_PERIOD_MS))

        // Can deactivate instantly if remaining volume is less than minimum
        const canDeactivateInstantly = canDeactivate && (ad.remaining < ad.min)

        acc[ad.id] = {
            active,
            canDeactivate,
            canRegretActivation,
            canDeactivateInstantly,
        }

        return acc
    }, {})
}

