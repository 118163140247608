import {
    BankDocumentType, DepositDeviationState, DocumentSubscriptionType,
    ExpectedDurationType,
    FundBuyOrderState, FundCategory, FundCreditDurationCategory, FundMorningstarCategory,
    FundPlacementTransactionType, FundSellOrderState, FundVffCategory,
    HistoryEntryType,
    OrganisationalRole,
    SecurityRole
} from '@fixrate/fixrate-query'
import {FundOrderState} from '#pages/portfolio-depositor/FundPlacements/FundOrders'
import {FundInventoryReturnPeriod} from '@fixrate/fixrate-report'
import { MatchType, RolesMatchType } from '#app/pages/organizations/OrganizationDetail/UsersAndRoles/views/BrregBoard'

export const securityRoleTMap: {[K in SecurityRole]: string} = {
    DEPOSITOR_ADMIN: 'enum-SecurityRole.DEPOSITOR_ADMIN',
    DEPOSITOR_ORDER: 'enum-SecurityRole.DEPOSITOR_ORDER',
    DEPOSITOR_ACCOUNT_HOLDER: 'enum-SecurityRole.DEPOSITOR_ACCOUNT_HOLDER',
    DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION: 'enum-SecurityRole.DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION',
    DEPOSITOR_PAYMENT: 'enum-SecurityRole.DEPOSITOR_PAYMENT',
    DEPOSITOR_ACCOUNTANT: 'enum-SecurityRole.DEPOSITOR_ACCOUNTANT',
    DEPOSITOR_VIEW: 'enum-SecurityRole.DEPOSITOR_VIEW',
    DEPOSITOR_SIGN_AUTHORIZATION: 'enum-SecurityRole.DEPOSITOR_SIGN_AUTHORIZATION',
    DEPOSITOR_PARTNER_ACCOUNTANT: 'enum-SecurityRole.DEPOSITOR_PARTNER_ACCOUNTANT',
    DEPOSITOR_PARTNER_ADVISOR: 'enum-SecurityRole.DEPOSITOR_PARTNER_ADVISOR',
    DEPOSITOR_PARTNER_SUPPORT: 'enum-SecurityRole.DEPOSITOR_PARTNER_SUPPORT',
    DEPOSITOR_DISPONENT: 'deprecated',
    DEPOSITOR_USER: 'deprecated',
    DEPOSITOR_SIGNING_AUTHORITY_IN_CONJUNCTION: 'deprecated',
    DEPOSITOR_ACCOUNT_HOLDER_WITH_AUTHORIZATION: 'deprecated',
    DEPOSITOR_SIGNING_AUTHORITY: 'deprecated',

    BANK_ADMIN: 'enum-SecurityRole.BANK_ADMIN',
    BANK_CUSTOMER_SERVICE: 'enum-SecurityRole.BANK_CUSTOMER_SERVICE',
    BANK_AD_MANAGER: 'enum-SecurityRole.BANK_AD_MANAGER',
    BANK_COORDINATOR: 'enum-SecurityRole.BANK_COORDINATOR',
    BANK_VIEW: 'enum-SecurityRole.BANK_VIEW',
    BANK_BACKOFFICE: 'deprecated',
    BANK_USER: 'deprecated',

    PARTNER_ADMIN: 'enum-SecurityRole.PARTNER_ADMIN',
    PARTNER_ADVISOR: 'enum-SecurityRole.PARTNER_ADVISOR',
    PARTNER_VIEW: 'enum-SecurityRole.PARTNER_VIEW',

    PARTNER_BANKSUPPORT: 'enum-SecurityRole.PARTNER_BANKSUPPORT',
    PARTNER_BANKSUPPORT_ADMIN: 'enum-SecurityRole.PARTNER_BANKSUPPORT_ADMIN',

    FIXRATE_ADMIN: 'enum-SecurityRole.FIXRATE_ADMIN',
    FIXRATE_OPERATIONS: 'enum-SecurityRole.FIXRATE_OPERATIONS',
    FIXRATE_USER: 'enum-SecurityRole.FIXRATE_USER',
    FIXRATE_SALES: 'enum-SecurityRole.FIXRATE_SALES',
    FIXRATE_FUND_CUSTOMER_SERVICE: 'enum-SecurityRole.FIXRATE_FUND_CUSTOMER_SERVICE',
    FIXRATE_MANAGEMENT: 'enum-SecurityRole.FIXRATE_MANAGEMENT',

    FUND_INTERMEDIARY_ADMIN: 'enum-SecurityRole.FUND_INTERMEDIARY_ADMIN',
    FUND_INTERMEDIARY_VIEW: 'enum-SecurityRole.FUND_INTERMEDIARY_VIEW',
}

export const organisationalRoleTMap: {[K in OrganisationalRole]: string} = {
    BOARD_CHAIRMAN: 'enum-OrganisationalRole.BOARD_CHAIRMAN',
    BOARD_MEMBER: 'enum-OrganisationalRole.BOARD_MEMBER',
    BOARD_DEPUTY_MEMBER: 'enum-OrganisationalRole.BOARD_DEPUTY_MEMBER',
    CEO: 'enum-OrganisationalRole.CEO',
    BENEFICIAL_OWNER: 'enum-OrganisationalRole.BENEFICIAL_OWNER',
    RIGHT_OF_SIGNATURE: 'enum-OrganisationalRole.RIGHT_OF_SIGNATURE',
    KEY_PERSON: 'enum-OrganisationalRole.KEY_PERSON',
}
type CombinedMatchType = keyof typeof MatchType | keyof typeof RolesMatchType;

export const personMatchTypeTMap: { [K in CombinedMatchType]: string } = {
    EXACT: 'enum-MatchType.EXACT',
    POSSIBLE: 'enum-MatchType.POSSIBLE',
    NONE: 'enum-MatchType.NONE',
    ROLES_DIFFER: 'enum-MatchType.ROLES_DIFFER',
    MISSING_IN_FIXRATE: 'enum-MatchType.MISSING_IN_FIXRATE',
    MISSING_IN_PROFF: 'enum-MatchType.MISSING_IN_PROFF'
};

export const historyEntryTypeTMap: {[K in HistoryEntryType]: string} = {
    INITIAL_ORDER_SENT_TO_BANK: 'enum-HistoryEntryType.INITIAL_ORDER_SENT_TO_BANK',
    ACCOUNT_AGREEMENT_UPLOADED: 'enum-HistoryEntryType.ACCOUNT_AGREEMENT_UPLOADED',
    ACCOUNT_AGREEMENT_SENT_TO_DEPOSITOR: 'enum-HistoryEntryType.ACCOUNT_AGREEMENT_SENT_TO_DEPOSITOR',
    ORDER_SENT_TO_DEPOSITOR: 'enum-HistoryEntryType.ORDER_SENT_TO_DEPOSITOR',
    SIGNED_ACCOUNT_AGREEMENT_SENT_TO_BANK: 'enum-HistoryEntryType.SIGNED_ACCOUNT_AGREEMENT_SENT_TO_BANK',
    ACCOUNT_ACTIVATED_CONFIRMATION: 'enum-HistoryEntryType.ACCOUNT_ACTIVATED_CONFIRMATION',
    TRANSACTION_SENT_TO_BANK: 'enum-HistoryEntryType.TRANSACTION_SENT_TO_BANK',
    SENT_TO_BANK: 'enum-HistoryEntryType.SENT_TO_BANK',
    ORDER_COMPLETED: 'enum-HistoryEntryType.ORDER_COMPLETED',
    ORDER_CANCELLED: 'enum-HistoryEntryType.ORDER_CANCELLED',
    ORDER_RESET: 'enum-HistoryEntryType.ORDER_RESET',
    INTEREST_RATE_CHANGE_CREATED: 'enum-HistoryEntryType.INTEREST_RATE_CHANGE_CREATED',
    INTEREST_RATE_CHANGE_ACCEPTED_BY_BANK: 'enum-HistoryEntryType.INTEREST_RATE_CHANGE_ACCEPTED_BY_BANK',
    INTEREST_RATE_CHANGE_ACCEPTED_BY_DEPOSITOR: 'enum-HistoryEntryType.INTEREST_RATE_CHANGE_ACCEPTED_BY_DEPOSITOR',
    INTEREST_RATE_CHANGE_IMPLEMENTED: 'enum-HistoryEntryType.INTEREST_RATE_CHANGE_IMPLEMENTED',
    INTEREST_RATE_CHANGE_CHANGE_DATE_CHANGED: 'enum-HistoryEntryType.INTEREST_RATE_CHANGE_CHANGE_DATE_CHANGED',
    INTEREST_RATE_CHANGE_SENT_TO_DEPOSITOR: 'enum-HistoryEntryType.INTEREST_RATE_CHANGE_SENT_TO_DEPOSITOR',
    INTEREST_RATE_CHANGE_CANCELLED: 'enum-HistoryEntryType.INTEREST_RATE_CHANGE_CANCELLED',
    SETTLEMENT_ACCOUNT_CHANGE_SENT_TO_BANK: 'enum-HistoryEntryType.SETTLEMENT_ACCOUNT_CHANGE_SENT_TO_BANK',
    DEPOSIT_TERMINATION_REQUESTED: 'enum-HistoryEntryType.DEPOSIT_TERMINATION_REQUESTED',
    DEPOSIT_CONDITIONAL_TERMINATION_REQUESTED: 'enum-HistoryEntryType.DEPOSIT_CONDITIONAL_TERMINATION_REQUESTED',
    DEPOSIT_TERMINATION_DATE_SET: 'enum-HistoryEntryType.DEPOSIT_TERMINATION_DATE_SET',
    DEPOSIT_TERMINATION_CONFIRMED: 'enum-HistoryEntryType.DEPOSIT_TERMINATION_CONFIRMED',
    DEPOSIT_TERMINATED: 'enum-HistoryEntryType.DEPOSIT_TERMINATED',
    DEPOSIT_CONDITIONALY_TERMINATED: 'enum-HistoryEntryType.DEPOSIT_CONDITIONALY_TERMINATED',
    DEPOSIT_TERMINATION_CANCELLED: 'enum-HistoryEntryType.DEPOSIT_TERMINATION_CANCELLED',
    DEPOSIT_EXTENSION_AGREEMENT_SENT_TO_DEPOSITOR: 'enum-HistoryEntryType.DEPOSIT_EXTENSION_AGREEMENT_SENT_TO_DEPOSITOR',
    DEPOSIT_EXTENSION_AGREEMENT_ACCEPTED: 'enum-HistoryEntryType.DEPOSIT_EXTENSION_AGREEMENT_ACCEPTED',
    DEPOSIT_EXTENSION_AGREEMENT_REJECTED: 'enum-HistoryEntryType.DEPOSIT_EXTENSION_AGREEMENT_REJECTED',
    DEPOSIT_EXTENSION_AGREEMENT_CANCELLED: 'enum-HistoryEntryType.DEPOSIT_EXTENSION_AGREEMENT_CANCELLED',
    DEPOSIT_DEVIATION_DETECTED: 'enum-HistoryEntryType.DEPOSIT_DEVIATION_DETECTED',
    DEPOSIT_DEVIATION_BANK_DETAILS_CHANGED: 'enum-HistoryEntryType.DEPOSIT_DEVIATION_BANK_DETAILS_CHANGED',
    DEPOSIT_DEVIATION_WAIT_FOR_CORRECTION: 'enum-HistoryEntryType.DEPOSIT_DEVIATION_WAIT_FOR_CORRECTION',
    DEPOSIT_DEVIATION_RESOLVED: 'enum-HistoryEntryType.DEPOSIT_DEVIATION_RESOLVED',
    NEW_CUSTOMER_DECLARATION_DISTRIBUTED: 'enum-HistoryEntryType.NEW_CUSTOMER_DECLARATION_DISTRIBUTED',
    NEW_AUTHORIZATION_DOCUMENT_DISTRIBUTED: 'enum-HistoryEntryType.NEW_AUTHORIZATION_DOCUMENT_DISTRIBUTED',
    DEPOSIT_BANK_CHANGED: 'enum-HistoryEntryType.DEPOSIT_BANK_CHANGED',
    FIXED_INTEREST_PERIOD_TERMS_SENT_TO_DEPOSITOR: 'enum-HistoryEntryType.FIXED_INTEREST_PERIOD_TERMS_SENT_TO_DEPOSITOR',
    FIXED_INTEREST_PERIOD_STARTED: 'enum-HistoryEntryType.FIXED_INTEREST_PERIOD_STARTED',
}

export const bankDocumentTypeTMap: {[K in BankDocumentType]: string} = {
    UNCATEGORIZED: 'enum-BankDocumentType.UNCATEGORIZED',
    MONTHLY_STATEMENT: 'enum-BankDocumentType.MONTHLY_STATEMENT',
    YEARLY_STATEMENT: 'enum-BankDocumentType.YEARLY_STATEMENT',
    CREDIT_MESSAGE: 'enum-BankDocumentType.CREDIT_MESSAGE',
    DEBIT_MESSAGE: 'enum-BankDocumentType.DEBIT_MESSAGE',
    OTHER: 'enum-BankDocumentType.OTHER',
    ERROR_WRONG_ADDRESS: 'enum-BankDocumentType.ERROR_WRONG_ADDRESS',
    ERROR_ILLEGAL_DOCUMENT_TYPE: 'enum-BankDocumentType.ERROR_ILLEGAL_DOCUMENT_TYPE',
    ERROR_DUPLICATE: 'enum-BankDocumentType.ERROR_DUPLICATE',
    ERROR: 'enum-BankDocumentType.ERROR',
    MULTICONTENT_DOCUMENT: 'enum-BankDocumentType.MULTICONTENT_DOCUMENT',
}

export const depositDeviationStateTMap: {[K in DepositDeviationState]: string} = {
    DETECTED: 'enum-DepositDeviationState.DETECTED',
    BANK_DETAILS_ADDED: 'enum-DepositDeviationState.BANK_DETAILS_ADDED',
    WAIT_FOR_CORRECTION: 'enum-DepositDeviationState.WAIT_FOR_CORRECTION',
    RESOLVED: 'enum-DepositDeviationState.RESOLVED',
}

export const depositDeviationStateShortTMap: {[K in DepositDeviationState]: string} = {
    DETECTED: 'enum-DepositDeviationState.DETECTED',
    BANK_DETAILS_ADDED: 'enum-DepositDeviationState.WAIT',
    WAIT_FOR_CORRECTION: 'enum-DepositDeviationState.WAIT',
    RESOLVED: 'enum-DepositDeviationState.RESOLVED',
}

export const expectedDurationTypeTMap: {[K in ExpectedDurationType]: string} = {
    UNKNOWN: 'enum-ExpectedDurationType.UNKNOWN',
    FROM_1_TO_6_MONTHS: 'enum-ExpectedDurationType.FROM_1_TO_6_MONTHS',
    FROM_6_TO_12_MONTHS: 'enum-ExpectedDurationType.FROM_6_TO_12_MONTHS',
    FROM_12_TO_24_MONTHS: 'enum-ExpectedDurationType.FROM_12_TO_24_MONTHS',
    OVER_24_MONTHS: 'enum-ExpectedDurationType.OVER_24_MONTHS',
}

export const fundPlacementTransactionTypeTMap: {[K in FundPlacementTransactionType]: string} = {
    BUY: 'enum-FundPlacementTransactionType.BUY',
    SELL: 'enum-FundPlacementTransactionType.SELL',
    DIVIDEND: 'enum-FundPlacementTransactionType.DIVIDEND',
    RETROCESSION: 'enum-FundPlacementTransactionType.RETROCESSION',
    RETROCESSION_CASH: 'enum-FundPlacementTransactionType.RETROCESSION_CASH',
    RETROCESSION_UNITS: 'enum-FundPlacementTransactionType.RETROCESSION_UNITS',
    EXCHANGE: 'enum-FundPlacementTransactionType.EXCHANGE',
    MOVE: 'enum-FundPlacementTransactionType.MOVE',
}

export const fundBuyOrderStateTMap: {[K in FundBuyOrderState]: string} = {
    AWAITING_SIGNATURE: 'enum-FundBuyOrderState.AWAITING_SIGNATURE',
    AWAITING_PAYMENT: 'enum-FundBuyOrderState.AWAITING_PAYMENT',
    AWAITING_PAYMENT_CONFIRMATION: 'enum-FundBuyOrderState.AWAITING_PAYMENT_CONFIRMATION',
    IN_PROGRESS: 'enum-FundBuyOrderState.IN_PROGRESS',
    EXECUTING_IN_MARKET: 'enum-FundBuyOrderState.EXECUTING_IN_MARKET',
    COMPLETED: 'enum-FundBuyOrderState.COMPLETED',
    CANCELLED: 'enum-FundBuyOrderState.CANCELLED',
    CANCELLED_BY_DEPOSITOR: 'enum-FundBuyOrderState.CANCELLED_BY_DEPOSITOR',
}

export const fundSellOrderStateTMap: {[K in FundSellOrderState]: string} = {
    AWAITING_SIGNATURE: 'enum-FundSellOrderState.AWAITING_SIGNATURE',
    IN_PROGRESS: 'enum-FundSellOrderState.IN_PROGRESS',
    EXECUTING_IN_MARKET: 'enum-FundSellOrderState.EXECUTING_IN_MARKET',
    TRANSFERRING_MONEY: 'enum-FundSellOrderState.TRANSFERRING_MONEY',
    COMPLETED: 'enum-FundSellOrderState.COMPLETED',
    CANCELLED: 'enum-FundSellOrderState.CANCELLED',
    CANCELLED_BY_DEPOSITOR: 'enum-FundSellOrderState.CANCELLED_BY_DEPOSITOR',
}

export const fundOrderStateTMap: {[K in FundOrderState]: string} = {
    AWAITING_SIGNATURE: 'enum-FundOrderState.AWAITING_SIGNATURE',
    AWAITING_PAYMENT: 'enum-FundOrderState.AWAITING_PAYMENT',
    AWAITING_PAYMENT_CONFIRMATION: 'enum-FundOrderState.AWAITING_PAYMENT_CONFIRMATION',
    TRANSFERRING_MONEY: 'enum-FundOrderState.TRANSFERRING_MONEY',
    IN_PROGRESS: 'enum-FundOrderState.IN_PROGRESS',
    EXECUTING_IN_MARKET: 'enum-FundOrderState.EXECUTING_IN_MARKET',
    COMPLETED: 'enum-FundOrderState.COMPLETED',
    CANCELLED: 'enum-FundOrderState.CANCELLED',
    CANCELLED_BY_DEPOSITOR: 'enum-FundOrderState.CANCELLED_BY_DEPOSITOR',
    '': '',
}

export const fundCategoryTMap: {[K in FundCategory]: string} = {
    MONEY_MARKET_FUND: 'enum-FundCategory.MONEY_MARKET_FUND',
    BOND_FUND: 'enum-FundCategory.BOND_FUND',
}


export const fundCreditDurationCategoryTMap: { [K in FundCreditDurationCategory]: string } = {
    ABOVE_ONE_AND_A_HALF_YEARS: 'enum-FundCreditDurationCategory.ABOVE_ONE_AND_A_HALF_YEARS',
    BELOW_ONE_AND_A_HALF_YEARS: 'enum-FundCreditDurationCategory.BELOW_ONE_AND_A_HALF_YEARS',
}

export const fundMorningstarCategoryTMap: { [K in FundMorningstarCategory]: string } = {
    INTEREST_RATE_NOK_BOND_HIGH: 'enum-FundMorningstarCategory.INTEREST_RATE_NOK_BOND_HIGH',
    INTEREST_RATE_NOK_BOND: 'enum-FundMorningstarCategory.INTEREST_RATE_NOK_BOND',
    INTEREST_RATE_NOK_BOND_SHORT: 'enum-FundMorningstarCategory.INTEREST_RATE_NOK_BOND_SHORT',
    INTEREST_RATE_NOK_BOND_ULTRASHORT: 'enum-FundMorningstarCategory.INTEREST_RATE_NOK_BOND_ULTRASHORT',
    INTEREST_RATE_NOK_BOND_GLOBAL: 'enum-FundMorningstarCategory.INTEREST_RATE_NOK_BOND_GLOBAL',
    INTEREST_RATE_NOK_BOND_OTHER: 'enum-FundMorningstarCategory.INTEREST_RATE_NOK_BOND_OTHER',
    INTEREST_RATE_EUR_MONEY_MARKET: 'enum-FundMorningstarCategory.INTEREST_RATE_EUR_MONEY_MARKET',
    INTEREST_RATE_EUR_MONEY_MARKET_SHORT: 'enum-FundMorningstarCategory.INTEREST_RATE_EUR_MONEY_MARKET_SHORT',
    INTEREST_RATE_EUR_BOND_ULTRASHORT: 'enum-FundMorningstarCategory.INTEREST_RATE_EUR_BOND_ULTRASHORT',
    INTEREST_RATE_USD_MONEY_MARKET: 'enum-FundMorningstarCategory.INTEREST_RATE_USD_MONEY_MARKET',
    INTEREST_RATE_USD_MONEY_MARKET_SHORT: 'enum-FundMorningstarCategory.INTEREST_RATE_USD_MONEY_MARKET_SHORT',
    INTEREST_RATE_USD_BOND_ULTRASHORT: 'enum-FundMorningstarCategory.INTEREST_RATE_USD_BOND_ULTRASHORT',
}

export const fundVffCategoryTMap: { [K in FundVffCategory]: string } = {
    MONEY_MARKET_FUND_WITH_LOW_RISK: 'enum-FundVffCategory.MONEY_MARKET_FUND_WITH_LOW_RISK',
    MONEY_MARKET_FUND: 'enum-FundVffCategory.MONEY_MARKET_FUND',
    INTERNATIONAL_MONEY_MARKET_FUND: 'enum-FundVffCategory.INTERNATIONAL_MONEY_MARKET_FUND',
    UNCLASSIFIED_MONEY_MARKET_FUND: 'enum-FundVffCategory.UNCLASSIFIED_MONEY_MARKET_FUND',
    BOND_FUND_0_125_CREDIT_RISK_1: 'enum-FundVffCategory.BOND_FUND_0_125_CREDIT_RISK_1',
    BOND_FUND_0_125_CREDIT_RISK_2: 'enum-FundVffCategory.BOND_FUND_0_125_CREDIT_RISK_2',
    BOND_FUND_0_125_CREDIT_RISK_3: 'enum-FundVffCategory.BOND_FUND_0_125_CREDIT_RISK_3',
    BOND_FUND_1_CREDIT_RISK_1: 'enum-FundVffCategory.BOND_FUND_1_CREDIT_RISK_1',
    BOND_FUND_1_CREDIT_RISK_2: 'enum-FundVffCategory.BOND_FUND_1_CREDIT_RISK_2',
    BOND_FUND_1_CREDIT_RISK_3: 'enum-FundVffCategory.BOND_FUND_1_CREDIT_RISK_3',
    BOND_FUND_3_CREDIT_RISK_1: 'enum-FundVffCategory.BOND_FUND_3_CREDIT_RISK_1',
    BOND_FUND_3_CREDIT_RISK_2: 'enum-FundVffCategory.BOND_FUND_3_CREDIT_RISK_2',
    BOND_FUND_3_CREDIT_RISK_3: 'enum-FundVffCategory.BOND_FUND_3_CREDIT_RISK_3',
    BOND_FUND_5_CREDIT_RISK_1: 'enum-FundVffCategory.BOND_FUND_5_CREDIT_RISK_1',
    BOND_FUND_5_CREDIT_RISK_2: 'enum-FundVffCategory.BOND_FUND_5_CREDIT_RISK_2',
    INTERNATIONAL_BOND_FUND: 'enum-FundVffCategory.INTERNATIONAL_BOND_FUND',
    UNCLASSIFIED_BOND_FUND: 'enum-FundVffCategory.UNCLASSIFIED_BOND_FUND',
    OTHER_NORWEGIAN_BOND_FUND: 'enum-FundVffCategory.OTHER_NORWEGIAN_BOND_FUND',
    OTHER_INTEREST_RATE_FUND: 'enum-FundVffCategory.OTHER_INTEREST_RATE_FUND',
    OTHER_LONG_MONEY_MARKET_FUND: 'enum-FundVffCategory.OTHER_LONG_MONEY_MARKET_FUND',
}

export const fundInventoryReturnPeriodTMap: { [K in FundInventoryReturnPeriod]: string } = {
    CURRENT: 'enum-FundInventoryReturnPeriod.CURRENT',
    YTD: 'enum-FundInventoryReturnPeriod.YTD',
    QTD: 'enum-FundInventoryReturnPeriod.QTD',
    LAST_12_MONTHS: 'enum-FundInventoryReturnPeriod.LAST_12_MONTHS',
    LAST_36_MONTHS: 'enum-FundInventoryReturnPeriod.LAST_36_MONTHS',
    LAST_60_MONTHS: 'enum-FundInventoryReturnPeriod.LAST_60_MONTHS',
}

export const documentSubscriptionTypeTMap: { [K in DocumentSubscriptionType]: string } = {
    ACCOUNT_STATEMENTS: 'enum-DocumentSubscriptionType.ACCOUNT_STATEMENTS',
    INTEREST_AND_BALANCE: 'enum-DocumentSubscriptionType.INTEREST_AND_BALANCE'
}
