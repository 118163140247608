/*
    Functions for sending commands to the command endpoint
*/

import { handleNetworkErrors, handleStatusCodeErrors } from '../errorhandling'
import config from '../../../config'
import { waitForCommandResponse } from '../waitForCommandResponse'

const API_BASE_URL = config().apiUrl

class ResponseError extends Error {
    constructor(...args) {
        super(...args)
    }
}

class CommandIdError extends Error {
    constructor(...args) {
        super(...args)
    }
}

async function registerCommand(dispatch, commandName, url, options: RequestInit): Promise<boolean> {
    const response = await fetch(url, options)

    await handleStatusCodeErrors(dispatch, response)

    if (!response.ok) {
        throw new ResponseError('Response was not ok')
    }

    const commandId = (await response.json()).commandId

    if (!commandId) {
        throw new CommandIdError('No command id')
    }

    console.log(`Command ${commandName} returned; Command ID: ${commandId}`)

    return commandId
}

export interface PostCommandResponse {
    rejected: boolean;
    waitForCommand: (timeout?: number) => Promise<boolean>;
}

export async function postCommandOptions(dispatch, commandName, url, options: RequestInit): Promise<PostCommandResponse> {
    try {
        const commandId = await registerCommand(dispatch, commandName, url, options)
        return {
            rejected: false,
            waitForCommand: waitForCommandResponse.bind(null, commandId)
        }
    } catch (err) {
        console.error(err)
        if (err instanceof ResponseError) {
            console.error(`Command response Error: ${err.message}`)
        } else if (err instanceof CommandIdError) {
            console.log(`Command ${commandName} returned without command id`)
        } else {
            handleNetworkErrors(dispatch)
        }
        return {
            rejected: true,
            waitForCommand: () => Promise.resolve(false)
        }
    }
}

export async function postCommand(dispatch, commandName, parameters): Promise<PostCommandResponse> {
    return postCommandOptions(dispatch, commandName, `${API_BASE_URL}/api/command`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: parameters && JSON.stringify({
            name: commandName,
            parameters: {...parameters},
        }),
    })
}
