export type SupportCategoryNameType = keyof typeof SUPPORT_PAGE_NAME_MAP

export const SUPPORT_PAGE_NAME_MAP = {
    "gettingStarted": {
        NO: "67373b80378f835a1c845fe7",
        SE: ""
    },
    "generalTerms": {
        NO: "67373d5f378f835a1c8462d6",
        SE: ""
    },
    "depositReports": {
        NO: "673c4e335bf702daa8b29d2d",
        SE: ""
    },
    "rolesBank": {
        NO: "673c4ee85bf702daa8b29e3c",
        SE: ""
    },
    "rolesDepositor": {
        NO: "673c4f625bf702daa8b29ee9",
        SE: ""
    },
    "roles": {
        NO: "673c4ee15bf702daa8b29e29",
        SE: ""
    },
    "rolesEmail": {
        NO: "673c51435bf702daa8b2a062",
        SE: ""
    },
    "authorizations": {
        NO: "673c50505bf702daa8b29fe2",
        SE: ""
    },
    "customerDeclaration": {
        NO: "673c52d95bf702daa8b2a272",
        SE: ""
    },
    "originOfFunds": {
        NO: "673c6c5b5bf702daa8b2a758",
        SE: ""
    },
    "settlementAccounts": {
        NO: "67373cbb378f835a1c8461e0",
        SE: ""
    },
    "depositorAds": {
        NO: "67373b1b378f835a1c845f75",
        SE: ""
    },
    "pep": {
        NO: "673c6c815bf702daa8b2a791",
        SE: ""
    },
    "portfolio": {
        NO: "673c4d555bf702daa8b29c78",
        SE: ""
    },
    "nationalIdentityNumber": {
        NO: "673c6e995bf702daa8b2a963",
        SE: ""
    },
    "interestRateChange": {
        NO: "673ca2ca5bf702daa8b2c665",
        SE: ""
    },
    "interestRateConvention": {
        NO: "67373f8a378f835a1c846730",
        SE: ""
    },
    "terminationFloatingInterest": {
        NO: "67373e76378f835a1c8464c2",
        SE: ""
    },
    "terminationFixedInterest": {
        NO: "67373e41378f835a1c846463",
        SE: ""
    },
    "termsBankAcceptingDeposits": {
        NO: "67373f57378f835a1c8466cc",
        SE: ""
    },
    "fundTermsAndConditions": {
        NO: "67373d89378f835a1c846343",
        SE: ""
    },
    "depositTermsOverview": {
        NO: "67373b3d378f835a1c845fa3",
        SE: ""
    },
    "ownershipKeyPeople": {
        NO: "673cad195bf702daa8b2ca14",
        SE: ""
    },
    "depositorAccountOpener": {
        NO: "67373d29378f835a1c84625f",
        SE: ""
    },
}
