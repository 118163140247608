import restEndpoint from './rest/rest-endpoint'
import * as actions from '#state/reducers/supportPane'
import {startLoadingSupportPane, stopLoadingSupportPane} from '#state/reducers/supportPane'
import {useDispatch} from 'react-redux'
import {useTracking} from '#services/useTracking'
import {useSelector} from '#state/useSelector'
import useCurrentCountryCode from './useCurrentCountryCode'
import { useSearchParams } from 'react-router-dom'
import { SupportCategoryNameType, SUPPORT_PAGE_NAME_MAP } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'

export default function useSupportPane() {
    const {trackEvent} = useTracking({page: 'SupportPaneView'})
    const [searchParams, setSearchParams] = useSearchParams()
    const supportPaneData = useSelector(state => state.supportPane)
    const isOpen = !!searchParams.get('supportId')
    const country = useCurrentCountryCode()

    function open() {
        setSearchParams({supportId: ""})
    }

    function show(supportCommonName: SupportCategoryNameType) {
        trackEvent({event: 'open-context-help'})
        const payloadId = SUPPORT_PAGE_NAME_MAP[supportCommonName][country]
        setSearchParams({supportId: payloadId})
    }

    function hide() {
        searchParams.delete('supportId')
        setSearchParams(searchParams)
    }

    return {
        open,
        show,
        hide,
        isLoading: supportPaneData.isLoading,
        isOpen
    }
}

